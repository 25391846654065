/**
 * Manage a bank of items, grabbing items sequentially and cycling back around when necessary.
 * Also supports applying the bank of items to another object array by adding the values from the bank as a specified field.
 */
export class CyclingBank<T> {
  private bank: T[];
  private currentIndex: number = 0;

  constructor(bank: T[]) {
    this.bank = bank;
  }

  /**
   * Get the next object in our bank, cycling around if necessary.
   * @returns {T} The next object.
   */
  getNextObject() {
    return this.bank[this.currentIndex++ % this.bank.length];
  }

  /**
   * Given an input array, add a new key to each object in the array with the next n values from our bank.
   * @param array The input array to add data to.
   * @param key The key that will be set to the value from our bank for each object in the array.
   * @returns The mutated array with generated key field added.
   */
  applyToArray<R, K extends string>(array: R[], key: string) {
    return array.map((item) => {
      return {
        ...item,
        [key]: this.getNextObject(),
      } as R & { [key in K]: T };
    });
  }

  isEmpty() {
    return this.bank.length == 0;
  }
}
