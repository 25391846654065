import { ScreenSizeListener, Size } from "./utils/screen-size-listener";
import { FeedGenerator } from "./generation/feed.generator";
import { ReactiveController } from "./utils/controller";
import { UserInteractionTracker } from "./interaction_tracking/user_interaction_tracker";
import { NoopDriver, SocialMediaDriver } from "./interaction_tracking/network";
import { ExperimentTestRecord } from "./interaction_tracking/experiment_event";
import { UnsupportedFeatureController } from "./components/unsupported-feature-screen/unsupported-feature-screen.controller";
import { MenuController } from "./components/menu/menu.controller";
import { ExperimentState } from "./index.models";
import { ManifestParser } from "./manifest/parser";
import { ExperimentManifest } from "./manifest/models/manifest.models";
import { computePostSizes } from "./utils/screen_resizer";

/**
 * The main application controller that manages all top level events and business logic for the experiment.
 * Primarily, this is responsible for loading and parsing the experiment manifest into posts to be displayed.
 * It also handles user interaction tracking with the page.
 */
export class ExperimentController extends ReactiveController<ExperimentState> {
  private screenSizeListener: ScreenSizeListener = new ScreenSizeListener();

  private debug: boolean = false;
  private subjectId?: string;
  private condition?: string;

  private parser?: ManifestParser;
  private serverDriver?: SocialMediaDriver;
  private interactionTracker?: UserInteractionTracker;
  private testId?: string;

  private manifest?: ExperimentManifest;

  constructor() {
    super({
      posts: [],
      menuController: new MenuController({ currentPage: "home" }),
      unsupportedFeatureController: new UnsupportedFeatureController(),
      mobileSupported: true,
      sponsored: [],
      birthdays: [],
      contacts: [],
    });
  }

  async initialize() {
    await super.initialize();

    const manifestExists = await this.fetchManifest();
    if (!manifestExists) return;

    const paramsValid = this.parseQueryParams();
    if (!paramsValid) return;

    try {
      await this.parseExperimentData();
    } catch (error) {
      console.error("FATAL: Parsing experiment manifest failed!", error);
      this.onManifestMissing(error);
      return;
    }

    this.screenSizeListener.initialize();
    this.screenSizeListener.stream.subscribe(this.onScreenSizeChanged);

    if (this.debug) {
      this.serverDriver = new NoopDriver();
    } else {
      this.serverDriver = new SocialMediaDriver();
    }

    this.interactionTracker = new UserInteractionTracker(
      this.serverDriver,
      this.onRecordUploaded,
      false,
      this.state.posts
    );
    this.interactionTracker.startTracking(this.subjectId);
  }

  async dispose() {
    await super.dispose();
    this.interactionTracker?.stopTracking();
  }

  /**
   * Parse experiment related params from the browser url.
   * Will set dataLoadError if required parameters are not provided or are invalid.
   * @returns false if all required params are not provided.
   */
  parseQueryParams() {
    const params = new URLSearchParams(window.location.search);

    this.debug = params.has("debug") ? params.get("debug") === "true" : false;
    this.subjectId = params.get("subjectId") ?? undefined;
    this.condition = params.get("condition") ?? undefined;

    if (this.condition == null) {
      this.onConditionMissing();
      return false;
    } else if (this.subjectId == null) {
      this.onSubjectIdMissing();
      return false;
    }

    return true;
  }

  onConditionMissing() {
    this.setState({
      error: "no-condition",
    });
  }

  onSubjectIdMissing() {
    this.setState({
      error: "no-subject-id",
    });
  }

  onManifestMissing(exception?: any) {
    this.setState({
      error: "no-manifest",
      exception: exception,
    });
  }

  /**
   * Update our state with the latest screen size data from our listener.
   * @param size The new screen size.
   */
  onScreenSizeChanged(size?: Size | undefined) {
    if (size == null) return;

    this.setState({
      posts: computePostSizes(this.state.posts, size.height),
      screenWidth: size.width,
      screenHeight: size.height,
    });
  }

  async fetchManifest() {
    const response = await fetch("./experiment_manifest.json");
    if (!response.ok) {
      this.onManifestMissing();
      return false;
    }

    this.manifest = await response.json();
    this.setState({
      contact: this.manifest?.contact,
      name: this.manifest?.name,
      instructions: this.manifest?.instructions,
      version: this.manifest?.version,
      mobileSupported: this.manifest?.supports_mobile ?? true,
    });
    return true;
  }

  async parseExperimentData() {
    this.parser = new ManifestParser(this.manifest!, this.condition!);
    await this.parser.initialize();

    const generator = new FeedGenerator(this.parser!);
    let posts = generator.generate();

    if (this.state.screenHeight != null) {
      posts = computePostSizes(posts, this.state.screenHeight);
    }

    this.setState({
      posts: posts,
      branding: this.parser.branding,
      sponsored: [generator.chosenSponsoredAd],
      birthdays: generator.userBirthdays,
      contacts: generator.contacts,
      shortcuts: generator.shortcuts,
    });
  }

  onRecordUploaded(record: ExperimentTestRecord) {
    this.testId = record.id;
  }

  getNetworkDriver(): SocialMediaDriver {
    return this.serverDriver!;
  }

  getTestId(): string {
    if (this.testId == null)
      throw new Error(
        "Cannot retrieve test id for experiment as it hasn't been established yet!"
      );
    return this.testId;
  }
}
